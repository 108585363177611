import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Why: React.FC<{}> = (props) => {
  const { t } = useTranslation();

  return (
    <section id="about" className="w-full flex-col">
      <div className="flex flex-col justify-center items-center">
        <div className="w-5/6 md:w-4/6">
          <h2 className="text-3xl text-cmAqua-6 font-medium mt-20 mb-2 font-super uppercase">
            {t('Why.heading', 'Why Monsta Party??')}
          </h2>
          <div className="flex flex-col md:flex-row items-center space-y-8 md:space-x-8 py-6">
            <div className="w-full mt-4 md:mt-0">
              <h3 className="mb-1 font-super text-xl">{t('Why.header.part0', 'NFT Problems')}</h3>
              <span className="flex items-center mb-10">
                <p className="text-lg">
                  {t(
                    'Why.description.part0',
                    'In the midst of market saturation, NFT communities are scrambling to find ways to provide passive yield to their holders to distinguish themselves and keep the floor prices up. One strategy is for such groups to create their own tokens to pay out to NFT holders. The trouble is that most of these tokens are going to be worthless unless the group is already extremely well established and valuable as a brand and a community. Either that or they need to figure out a way to create use cases for their currency other than just for holders to sell.',
                  )}
                </p>
              </span>
              <h3 className="mb-1 font-super text-xl">{t('Why.header.part1', 'The Solution')}</h3>
              <span className="flex items-center mb-10">
                <p className="text-lg">
                  {t(
                    'Why.description.part1',
                    'Party Monstas are backed by the value of an emerging and successful cryptocurrency and DeFi protocol, Cake Monster ($MONSTA). Cake Monster is itself backed by an ever increasing vault of the most valuable token on the Binance Smart Chain, $CAKE, and features a hybrid monetary policy that aims to sustainably reward holders and battle the shortfalls of traditional ecosystems. With all that support, Monsta Party NFTs will NEVER go to zero, unlike many other avatar projects that fizzle out when the initial hype wears off.',
                  )}
                </p>
              </span>
              <h3 className="mb-1 font-super text-xl">{t('Why.header.part2', 'The Future')}</h3>
              <span className="flex items-center ">
                <p className="text-lg">
                  {t(
                    'Why.description.part2',
                    "Party Monstas begin life with everything going for them by following in their father's footsteps.. They are the children of an established brand and character “The Cake Monster” who has his own fun community and meme culture as well as an ongoing series of animated cartoons.",
                  )}
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Why;
