import React, { useState, useEffect } from 'react';

import OnImagesLoaded from 'react-on-images-loaded';
import Fade from 'react-reveal/Fade';
import Swing from 'react-reveal/Swing';

import imgMonsterLoader from '../images/loader-img.png';

const MyPageLoader: React.FC<{ forceShow?: boolean }> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [finished, setFinished] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <>
      <>
        <OnImagesLoaded
          onLoaded={() => {
            setTimeout(() => {
              setLoading(false);
              setFinished(true);
              setTimeout(() => {
                setShowLoader(false);
              }, 500);
            }, 1000);
          }}
          onTimeout={() => {
            setLoading(false);
            setFinished(true);
            setTimeout(() => {
              setShowLoader(false);
            }, 500);
          }}
          timeout={7000}
        >
          {showLoader || props.forceShow ? (
            <div
              style={{ backgroundColor: '#050527' }}
              className={`fixed z-50 loader w-screen h-screen flex justify-center items-center transition duration-500 ease-in-out ${
                !finished ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <div>
                {loading ? (
                  <Fade bottom>
                    <Swing forever>
                      <img loading={'eager'} src={imgMonsterLoader} className={'w-24'} />
                    </Swing>
                  </Fade>
                ) : null}
              </div>
            </div>
          ) : null}
          {props.children}
        </OnImagesLoaded>
      </>
    </>
  );
};

export default MyPageLoader;
