import React from 'react';

import { Helmet } from 'react-helmet';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';

const I18nSEOMeta: React.FC<{ siteUrl: string }> = (props) => {
  const { t, i18n } = useTranslation();
  const { languages } = useI18next();

  return (
    <>
      {/*
        The changes done by this component may not be visible in the production mode.
        To see changes
        npm build
        npm server
      */}
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        {/* <!-- Google SEO hreflang tags for international website --> */}
        {languages.map((locale) => {
          if (locale == 'en') {
            return <link rel="alternate" href={props.siteUrl} hrefLang={locale} key={locale} />;
          }
          return (
            <link
              rel="alternate"
              href={`https://monsta.party/${locale}/`}
              hrefLang={locale}
              key={locale}
            />
          );
        })}
      </Helmet>
    </>
  );
};
export default I18nSEOMeta;
