import * as React from 'react';
import { useEffect } from 'react';
import 'intersection-observer';
import Meta from './sections/Meta';
import Header from './sections/Header';
import Footer from './sections/Footer';
import Hero from './sections/Hero';
import Why from './sections/Why';
import MyPageLoader from '../components/MyPageLoader';
import About from './sections/About';
import Roadmap from './sections/Roadmap';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Team from './sections/Team';
import I18nSEOMeta from '../components/I18nSEOMeta';
import Video from './sections/Video';
// import CountdownTimer from './sections/CountdownTimer';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const IndexPage: React.FC<{}> = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (localStorage.theme === 'light') {
      document.documentElement.classList.add('light');
      localStorage.theme = 'light';
    } else {
      document.documentElement.classList.remove('light');
      document.documentElement.classList.add('dark');
      localStorage.theme = 'dark';
    }
  });

  return (
    <main
      className={`overflow-x-hidden text-white header-bg ${i18n.language != 'en' ? 'nonEN' : ''}`}
      style={{ backgroundColor: '#050527' }}
    >
      <Meta />
      <I18nSEOMeta siteUrl={'https://monsta.party'} />
      <MyPageLoader>
        <Header />
        <Hero />
        {/* <CountdownTimer /> */}
        <Video />
        <About />
        <Why />
        <Team />
        <Roadmap />
        <Footer />
      </MyPageLoader>
    </main>
  );
};

export default IndexPage;
